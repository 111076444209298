var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "append-to-body": true,
        "close-on-click-modal": false,
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [
          _c("i"),
          _c("span", { staticStyle: { "font-size": "16px" } }, [
            _vm._v("授权校区"),
          ]),
        ]
      ),
      _c("div", { staticStyle: { display: "flex" } }, [
        _c(
          "div",
          { staticStyle: { width: "60%" } },
          [
            _c(
              "el-table",
              {
                ref: "multipleTable",
                staticStyle: {
                  width: "100%",
                  "max-height": "400px",
                  "overflow-y": "auto",
                },
                attrs: {
                  data: _vm.tableData3,
                  "tooltip-effect": "dark",
                  border: "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: { label: "校区名称", align: "center", prop: "name" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [_vm._v(" " + _vm._s(scope.row.name) + " ")]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "操作", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.flagpage === 2
                            ? _c(
                                "div",
                                [
                                  !scope.row.isSelect
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.chooseItem(
                                                scope.row,
                                                scope.$index
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("选择")]
                                      )
                                    : _vm._e(),
                                  scope.row.isSelect
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "success",
                                            size: "mini",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.chooseItem(
                                                scope.row,
                                                scope.$index
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("已选择")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.flagpage === 1
                            ? _c(
                                "div",
                                [
                                  !scope.row.isSelect
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.selectChildDate(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("选择")]
                                      )
                                    : _vm._e(),
                                  scope.row.isSelect
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "success",
                                            size: "mini",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.unselectChildDate(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("已选择")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }