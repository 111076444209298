<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :append-to-body="true"
    @open="openDialog"
    :close-on-click-modal="false"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span style="font-size: 16px;">授权校区</span>
    </div>
    <div style="display: flex;">
      <div style="width:60%;">
        <el-table
          ref="multipleTable"
          :data="tableData3"
          tooltip-effect="dark"
          border
          style="width:100%;max-height:400px;overflow-y: auto">
          <el-table-column label="校区名称"  align="center" prop="name">
            <template slot-scope="scope">
              {{scope.row.name}}
            </template>
          </el-table-column>
          <el-table-column label="操作"  align="center">
            <template slot-scope="scope">
              <div v-if="flagpage === 2">
                <el-button v-if="!scope.row.isSelect" type="primary" size="mini" @click="chooseItem(scope.row,scope.$index)">选择</el-button>
                <el-button v-if="scope.row.isSelect" type="success" size="mini" @click="chooseItem(scope.row,scope.$index)">已选择</el-button>
              </div>
              <div  v-if="flagpage === 1">
                <el-button v-if="!scope.row.isSelect" type="primary" size="mini" @click="selectChildDate(scope.row)">选择</el-button>
                <el-button v-if="scope.row.isSelect" type="success" size="mini" @click="unselectChildDate(scope.row)">已选择</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </el-dialog>
</template>

<script>
  import { getMyCampus} from '@/api/frontbusiness'
  import { pricegrandCampus,priceungrandCampus} from '@/api/system/course'
  export default {

    props: {
      flagpage: {
        default:1,
      },
      show: {
        default: false,
        type: Boolean
      },
      selectcampusData:{
        default: {},
        type:Object
      },
    },
    data () {
      return {
        tableData3: [],
        thembDataList: [],
      }
    },
    methods: {
      chooseItem(row,index){
        if(!row.isSelect) {
          this.pricegrandCampus(row,index)
        }else {
          this.priceungrandCampus(row,index)
        }
      },
      //新增页面选择校区
      selectChildDate(row) {
        let object = {}
        object.campusId = row.id
        this.thembDataList.push(object)
        row.isSelect = true
        this.$emit("updateList",this.thembDataList)
      },
      //新增页面取消选择校区
      unselectChildDate(row) {
        this.thembDataList.forEach((item,index)=>{
          if(item.campusId === row.id) {
            this.thembDataList.splice(index,1)
          }
        })
        row.isSelect = false
        this.$emit("updateList",this.thembDataList)
      },
      openDialog () {
          this.thembDataList = this.selectcampusData.productCampusBeans
          this.getMyCampus()
      },
      async getMyCampus() {
        const response = await getMyCampus({})
        this.tableData3 = this.getLastDataList(response.body,this.thembDataList)
      },

      getLastDataList(originDataList,selectDataList) {
        originDataList.forEach((originItem)=> {
          originItem.isSelect = false
          originItem.grandId = ""
        })
        selectDataList.forEach((selectItem)=> {
          originDataList.forEach((originItem)=> {
            if(originItem.id === selectItem.campusId) {
              originItem.isSelect = true
              originItem.grandId = selectItem.id
            }
          })
        })
        return originDataList
      },

      async priceungrandCampus(row,index) {
        const response = await priceungrandCampus( {
          grandId:row.grandId
        })
        if(response && response.state === 'success') {
          $msg("取消授权成功",0);
          this.thembDataList.forEach((item,index)=>{
            if(item.campusId === row.id) {
              this.thembDataList.splice(index,1)
            }
          })
          row.isSelect = false
          this.$emit("updateList",this.thembDataList)
        }
      },
      async pricegrandCampus(row,index) {
        const response = await pricegrandCampus( {
          priceId:this.selectcampusData.id,
          productId:this.selectcampusData.productId,
          campusId:row.id
        })
        if(response && response.state === 'success') {
          $msg("授权成功",0);
          this.tableData3[index].grandId = response.body
          let object = {}
          object.campusId = row.id
          object.id = response.body
          this.thembDataList.push(object)
          row.isSelect = true
          this.$emit("updateList",this.thembDataList)
        }
      },

      close () { this.$emit('close') },
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../style/dialog.scss";
  @import "../../../assets/font/iconfont.css";
  .centerCls {
    display: flex;
    align-items: center
  }
  p {
    line-height: 30px;
  }
</style>
