var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c("span", { staticClass: "title" }, [_vm._v("收费课程管理")]),
      _c(
        "el-row",
        { staticClass: "row-bg", staticStyle: { width: "40%" } },
        [
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "primary" },
              on: { click: _vm.add },
            },
            [_vm._v("新增收费课程")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "tb-list",
          staticStyle: { width: "40%" },
          attrs: {
            data: _vm.dataList,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "名称", align: "center", prop: "productName" },
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.edit(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        staticStyle: { width: "40%" },
        attrs: {
          "current-page": _vm.pagination.currentPage,
          "page-sizes": _vm.pagination.pageSizes,
          "page-size": _vm.pagination.pageSize,
          layout: _vm.pagination.layout,
          total: _vm.pagination.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c("add-course", {
        attrs: { show: _vm.courseDialog, courseData: _vm.courseData },
        on: {
          close: function ($event) {
            _vm.courseDialog = false
            _vm.courseData = null
          },
          updateView: _vm.getProductList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }