<template>
  <el-row class="table" >
    <span class="title">收费课程管理</span>
    <el-row class="row-bg"style="width: 40%">
      <el-button type="primary" @click="add" class="tb-button">新增收费课程</el-button>
    </el-row>
    <el-table
      :data="dataList"
      border
      fit
      style="width:40%"
      highlight-current-row
      class="tb-list"
    >
      <el-table-column label="名称" align="center" prop="productName"></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="edit(scope.row)">编辑</el-button>
<!--          <el-button type="text" @click="del(scope.row)">删除</el-button>-->
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="width: 40%"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>
    <!--  -->
    <add-course :show="courseDialog" @close="courseDialog=false;courseData=null" :courseData="courseData" @updateView="getProductList"></add-course>
  </el-row>
</template>

<script>
import addCourse from '@/components/system/addCourse'
import { getProductList, deletProduct } from '@/api/system/course'

export default {
  components: {
    addCourse
  },
  data () {
    return {
      dataList: [
        {
          name: 'K系列',
          unit: '课时',
          price: '150'
        },
        {
          name: 'P系列',
          unit: '课时',
          price: '120'
        },
        {
          name: 'S系列',
          unit: '课时',
          price: '75'
        }
      ],
      courseDialog: false,
      courseData: null,
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      }
    }
  },
  created () {
    this.getProductList()
  },
  methods: {
    async getProductList () {
      const res = await getProductList({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        needCount: true
      })
      this.dataList = res.body.list
      this.pagination.total = res.body.total
    },
    add () {
      this.courseDialog = true
    },

    edit (row) {
      this.courseData = row
      this.courseDialog = true
    },

    del (row) {
      this.$confirm('是否删除当前课程', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await deletProduct({
            productId: row.id
          })
          window.$msg('删除成功')
          this.getProductList()
        })
    },

    // 分页
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getProductList()
    },
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getProductList()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../style/table.scss';
</style>
