var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "before-close": _vm.close,
        "close-on-click-modal": false,
        visible: _vm.show,
        top: "7vh",
        width: "50%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [
          _c("i"),
          _c("span", { domProps: { textContent: _vm._s(_vm.courseTitle) } }),
        ]
      ),
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-position": "left",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "名称：", prop: "productName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入收费课程名称" },
                model: {
                  value: _vm.formData.productName,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "productName", $$v)
                  },
                  expression: "formData.productName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "默认单次消费数量：",
                prop: "defaultAmunt",
                "label-width": "180px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "80px" },
                        attrs: { controls: false, min: 1, placeholder: "数量" },
                        model: {
                          value: _vm.formData.defaultAmunt,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "defaultAmunt", $$v)
                          },
                          expression: "formData.defaultAmunt",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _vm._v("(每排1次课，记多少课时课消)"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.flagpage === 2
            ? _c(
                "el-row",
                { staticStyle: { width: "100%", "text-align": "center" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.editCourseMessage },
                    },
                    [_vm._v("更新信息")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("div", [
        _c("label", [
          _c("span", { staticStyle: { color: "red" } }, [_vm._v("* ")]),
          _vm._v("价格方案 : "),
        ]),
      ]),
      _c(
        "el-table",
        {
          staticClass: "tb-list",
          attrs: {
            data: _vm.priceSchemesList,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "名称", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.rowState == 3
                      ? _c("span", [_vm._v(_vm._s(scope.row.priceName))])
                      : _c("el-input", {
                          attrs: { placeholder: "请输入名称" },
                          model: {
                            value: scope.row.priceName,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "priceName", $$v)
                            },
                            expression: "scope.row.priceName",
                          },
                        }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "收费方式", align: "center", width: _vm.typeWidth },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.rowState == 3
                      ? _c("div", [
                          scope.row.isTerm == "1"
                            ? _c("span", [
                                _vm._v(" 按期收费 "),
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.termAmount) +
                                      " 课时/期 "
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          scope.row.isTerm == "2"
                            ? _c("span", [_vm._v(" 按课时收费 ")])
                            : _vm._e(),
                        ])
                      : _c(
                          "div",
                          [
                            _c(
                              "el-row",
                              {
                                staticClass: "row-bg",
                                staticStyle: { width: "100%" },
                                attrs: { type: "flex", justify: "center" },
                              },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 10 } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "tb-select",
                                        on: {
                                          change: function ($event) {
                                            return _vm.handleChange(
                                              scope.row.isTerm
                                            )
                                          },
                                        },
                                        model: {
                                          value: scope.row.isTerm,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "isTerm", $$v)
                                          },
                                          expression: "scope.row.isTerm",
                                        },
                                      },
                                      _vm._l(
                                        _vm.showList,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.label,
                                              value: item.value,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                scope.row.isTerm == "1"
                                  ? _c("el-col", { attrs: { span: 14 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticStyle: { flex: "2" },
                                            attrs: {
                                              placeholder: "请输入课时",
                                            },
                                            model: {
                                              value: scope.row.termAmount,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "termAmount",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.termAmount",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            { staticStyle: { flex: "1" } },
                                            [_vm._v("课时/期")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "单价", align: "center", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.rowState == 3
                      ? _c("span", [
                          _vm._v(" " + _vm._s(scope.row.originalPrice) + " "),
                          scope.row.isTerm == "1"
                            ? _c("span", [_vm._v("元/期")])
                            : _vm._e(),
                          scope.row.isTerm == "2"
                            ? _c("span", [_vm._v("元/课时")])
                            : _vm._e(),
                        ])
                      : _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { flex: "2" },
                              attrs: { placeholder: "请输入单价" },
                              model: {
                                value: scope.row.originalPrice,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "originalPrice", $$v)
                                },
                                expression: "scope.row.originalPrice",
                              },
                            }),
                            scope.row.isTerm == "1"
                              ? _c("span", { staticStyle: { flex: "1" } }, [
                                  _vm._v("元/期"),
                                ])
                              : _vm._e(),
                            scope.row.isTerm == "2"
                              ? _c("span", { staticStyle: { flex: "1" } }, [
                                  _vm._v("元/课时"),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "授权校区", align: "center", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.showCampusDialog(scope.$index)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.productCampusBeans.length) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.rowState !== 3
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.addItem(scope.row, scope.$index)
                              },
                            },
                          },
                          [_vm._v("确认")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.editItem(scope.$index)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteItem(scope.$index)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "addButton", on: { click: _vm.addPriceSchemes } },
        [_vm._v("添加")]
      ),
      _vm.flagpage === 1
        ? _c(
            "el-row",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  staticClass: "dialog-btn",
                  attrs: { type: "primary" },
                  on: { click: _vm.saveAddCourse },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "dialog-btn",
                  attrs: { type: "info" },
                  on: { click: _vm.close },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("course-campus-list", {
        attrs: {
          flagpage: _vm.flagpage,
          show: _vm.showcampuslistDialog,
          selectcampusData: _vm.selectcampusData,
        },
        on: {
          updateList: _vm.getChildDate,
          close: function ($event) {
            _vm.showcampuslistDialog = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }