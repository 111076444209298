<template>
  <el-dialog
    :before-close="close"
    :close-on-click-modal="false"
    :visible.sync="show"
    @open="openDialog"
    top="7vh"
    width="50%"
    >
    <div class="dia-tit" slot="title">
      <i></i>
      <span v-text="courseTitle"></span>
    </div>
    <el-form :model="formData" :rules="rules" label-position="left" label-width="100px" ref="formData">
      <el-form-item label="名称：" prop="productName">
        <el-input placeholder="请输入收费课程名称" v-model="formData.productName"></el-input>
      </el-form-item>
      <el-form-item label="默认单次消费数量："  prop="defaultAmunt" label-width="180px">
        <el-row>
          <el-col :span="4">
        <el-input-number :controls="false"  :min="1" placeholder="数量" style="width: 80px;" v-model="formData.defaultAmunt"></el-input-number>
          </el-col>
            <el-col :span="12">(每排1次课，记多少课时课消)</el-col>
        </el-row>
      </el-form-item>
      <el-row style="width: 100%;text-align: center"  v-if="flagpage === 2">
        <el-button @click="editCourseMessage" size="mini" type="primary">更新信息</el-button>
<!--        <el-button @click="close" size="mini" style="margin-left: 50px;" type="info">取 消</el-button>-->
      </el-row>
    </el-form>
    <div>
      <label><span style="color:red;">* </span>价格方案 : </label>
    </div>
    <el-table
      :data="priceSchemesList"
      border
      fit
      highlight-current-row
      class="tb-list"
    >
      <el-table-column label="名称" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.rowState == 3">{{scope.row.priceName}}</span>
          <el-input v-else  v-model="scope.row.priceName" placeholder="请输入名称"/>
        </template>
      </el-table-column>
      <el-table-column label="收费方式" align="center" :width="typeWidth">
        <template slot-scope="scope">
          <div  v-if="scope.row.rowState == 3">
            <span v-if="scope.row.isTerm == '1'">
              按期收费
              <span>
              {{scope.row.termAmount}} 课时/期
              </span>
            </span>
            <span v-if="scope.row.isTerm == '2'">
              按课时收费
            </span>
          </div>
          <div  v-else>
            <el-row type="flex" class="row-bg" justify="center" style="width: 100%">
              <el-col :span="10">
                <el-select
                  v-model="scope.row.isTerm"
                  class="tb-select"
                  @change="handleChange(scope.row.isTerm)"
                >
                  <el-option
                    v-for="(item, index) in showList"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col  :span="14"  v-if="scope.row.isTerm == '1'">
                <div style="display: flex;align-items: center">
                  <el-input style="flex: 2" v-model="scope.row.termAmount"  placeholder="请输入课时"/>
                  <span  style="flex: 1">课时/期</span>
                </div>
              </el-col>
            </el-row>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="单价" align="center" width="180">
        <template slot-scope="scope">
          <span v-if="scope.row.rowState == 3">
                {{scope.row.originalPrice}}
                <span v-if="scope.row.isTerm == '1'">元/期</span>
                <span v-if="scope.row.isTerm == '2'">元/课时</span>
          </span>
          <div style="display: flex;align-items: center"  v-else  >
            <el-input style="flex: 2" v-model="scope.row.originalPrice"  placeholder="请输入单价"/>
            <span  style="flex: 1" v-if="scope.row.isTerm == '1'">元/期</span>
            <span  style="flex: 1" v-if="scope.row.isTerm == '2'">元/课时</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="授权校区" align="center" width="100">
        <template slot-scope="scope">
              <span  style="cursor: pointer" @click="showCampusDialog(scope.$index)">
                {{scope.row.productCampusBeans.length}}
              </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" >
        <template slot-scope="scope">
          <el-button v-if="scope.row.rowState !== 3" @click="addItem(scope.row,scope.$index)" size="mini" type="primary">确认</el-button>
          <el-button v-else @click="editItem(scope.$index)" size="mini" type="primary">编辑</el-button>
          <el-button @click="deleteItem(scope.$index)" size="mini" type="primary">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="addButton" @click="addPriceSchemes">添加</div>
    <el-row slot="footer" v-if="flagpage === 1">
      <el-button @click="saveAddCourse" class="dialog-btn" type="primary">确 定</el-button>
      <el-button @click="close" class="dialog-btn" type="info">取 消</el-button>
    </el-row>
    <course-campus-list :flagpage="flagpage" @updateList = "getChildDate" :show="showcampuslistDialog"  @close="showcampuslistDialog=false" :selectcampusData="selectcampusData"></course-campus-list>
  </el-dialog>
</template>

<script>
import { addProduct, editProduct,addPrice,editPrice,deletPrice } from '@/api/system/course'
import courseCampusList from '@/components/system/coursecampuslist'
export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    courseData: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },
  data () {
    return {
      courseTitle: '',
      formData: {
        defaultAmunt:1,
      },
      rules: {
        productName: [{ required: true, message: '请输入收费课程名称', trigger: 'blur' }],
        defaultAmunt: [{ required: true, message: '请输入单次消费数量', trigger: 'blur' }],
      },
      priceSchemesList:[],
      showcampuslistDialog:false,
      selectcampusData:{},
      showList: [
        {
          label: '按期收费',
          value: "1"
        },
        {
          label: '按课时收费',
          value: "2"
        }
      ],
      typeWidth:300,
      flagpage:1,   //1 新增页面，2编辑页面
      currentCampusIndex:0,
    }
  },
  components: {
    courseCampusList
  },
  methods: {
    async addPrice(row,index) {
      const res = await addPrice({
        productId: this.courseData.id,
        priceName: row.priceName,
        originalPrice: row.originalPrice,
        isTerm: row.isTerm,
        termAmount: row.termAmount,
      })
      if (res.state === 'success') {
        window.$msg('新增价格方案成功')
        this.priceSchemesList[index].id = res.body
        this.priceSchemesList[index].productId = this.courseData.id
      } else {
        window.$msg(res.errMsg, 2)
      }
    },
    async editPrice(row) {
      const res = await editPrice({
        id:row.id,
        productId: row.productId,
        priceName: row.priceName,
        originalPrice: row.originalPrice,
        isTerm: row.isTerm,
        termAmount: row.termAmount,
      })
      if (res.state === 'success') {
        window.$msg('编辑价格方案成功')
      } else {
        window.$msg(res.errMsg, 2)
      }
    },
    async deletPrice(row,index) {
      const res = await deletPrice({
        priceId: row.id,
      })
      if (res.state === 'success') {
        window.$msg('删除价格方案成功')
        this.priceSchemesList.splice(index,1)
      } else {
        window.$msg(res.errMsg, 2)
      }
    },
    getChildDate(childDate) {
      this.priceSchemesList[this.currentCampusIndex].productCampusBeans = childDate
    },
    //编辑更新信息
    async editCourseMessage() {
     await editProduct({
       id:this.courseData.id,
       productName:this.formData.productName,
       defaultAmunt:this.formData.defaultAmunt,
      }).then(res => {
        if (res.state === 'success') {
          $msg("基础信息更新成功")
        }
      })
    },
    //新增保存信息
    saveAddCourse() {
      this.$refs.formData.validate(valid => {
        if (valid) {
          if (this.courseData === null) {
            let gotoNext = false
            if(this.priceSchemesList.length === 0) {
              $msg("价格方案不能为空",2)
              return false
            }else {
              for (let i = 0; i < this.priceSchemesList.length; i++) {
                if(this.priceSchemesList[i].rowState !== 3) {
                  $msg("价格方案有未保存的项",2)
                  gotoNext = true
                  break;
                }
              }
            }
            if(!gotoNext) {
              var lastData ={};
              lastData.defaultAmunt = this.formData.defaultAmunt
              lastData.productName = this.formData.productName
              lastData.productPriceBeans = this.priceSchemesList
              addProduct(lastData).then(res => {
                if (res.state === 'success') {
                  this.close()
                  this.$emit('updateView')
                }
              })
            }
          }
        }
      })
    },
    addItem(row,index) {
      if(row.priceName+"".trim() === "") {
        $msg("价格名称不能为空",2)
        return false
      }else if(row.originalPrice+"".trim() === ""){
        $msg("课程单价不能空",2)
        return false
      }else if(row.isTerm === '1' && row.termAmount+"".trim() === "") {
        $msg("请输入按期收费的课时数",2)
        return false
      }else {
        this.priceSchemesList[index].rowState = 3
        if(this.flagpage === 2) {
          if(row.productId === undefined || row.productId === null) { // 点击确定走新增逻辑
            this.addPrice(row,index)
          }else{ // 点击确定走编辑逻辑
            this.editPrice(row)
          }
        }
      }
    },
    editItem(index) {
      this.priceSchemesList[index].rowState = 2
    },
    deleteItem(index) {
      if(this.flagpage === 1) {
        this.priceSchemesList.splice(index,1)
      }else {
        if(this.priceSchemesList[index].rowState !== 3) {
          this.priceSchemesList.splice(index,1)
        }else {
          this.deletPrice(this.priceSchemesList[index],index)
        }
      }
    },
    handleChange(currentState){

    },
    showCampusDialog(index) {
        if(this.priceSchemesList[index].rowState !==3 ) {
          $msg("请确认价格方案后，在授权校区",2)
          return false
        }
        this.currentCampusIndex = index
        this.selectcampusData = this.priceSchemesList[index]
        this.showcampuslistDialog = true
    },
    openDialog () {
      this.formData = {
        defaultAmunt:1,
        isTerm: '1',
        termAmount:"1"
      }
      if (this.courseData === null) {
        this.courseTitle = '新增收费课程'
        this.flagpage = 1
        this.priceSchemesList = []
      } else {
        this.courseTitle = '编辑收费课程'
        this.flagpage = 2
        this.formData = Object.assign({}, this.courseData)
        this.priceSchemesList = []
        this.courseData.productPriceBeans.forEach(item=>{
          let object = {}
          object.id = item.id
          object.productId = item.productId
          object.rowState = 3 // 1.代表新增的一条数据 2.代表可以正在编辑 3.代表已经确认的数据
          object.priceName = item.priceName
          object.originalPrice = item.originalPrice //单价
          object.isTerm = item.isTerm+"" // 是否按照期收费：1是 2否
          object.termAmount = item.termAmount
          object.productCampusBeans = item.productCampusBeans
          this.priceSchemesList.push(object)
        })
        console.log("this.courseData-------"+JSON.stringify(this.courseData))
      }
    },
    addPriceSchemes() {
      let object = {}
      object.rowState = 1 // 1.代表新增的一条数据 2.代表可以正在编辑 3.代表已经确认的数据
      object.priceName = ""
      object.originalPrice = "" //单价
      object.isTerm = '1' // 是否按照期收费：1是 2否
      object.termAmount = ""
      object.productCampusBeans = []
      this.priceSchemesList.push(object)
    },
    // 关闭
    close () {
      this.$emit('close')
      this.$emit('updateView')
    },
  }
}
</script>

<style lang="scss" scoped>
  .addButton {
    width: 60%;
    margin: auto;
    line-height: 30px;
    height: 30px;
    border: dashed 1px #A6A6A6;
    margin-top: 15px;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
  }
@import '../../../style/dialog.scss';
</style>
